//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'

export default {
	computed: {
		toggleSidebar() {
			return this.$store.getters['nav/toggleSidebar']
		}
	},
	
	methods: {
		clickToogleMenu(){
			this.$store.dispatch('nav/toggleSidebar')
			if(this.$store.getters['nav/toggleSidebar']){
				document.querySelector('body').style.overflow = 'hidden'
			}else{
				document.querySelector('body').style.removeProperty('overflow')
			}
		}
	},
}
