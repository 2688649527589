//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import logo from "~/components/Logo.vue"
import FooterLink from "~/components/layout/FooterLink.vue"
import SocialLink from "~/components/layout/SocialLink.vue"
import OppositeLang from "~/components/layout/OppositeLang.vue"

export default {
	components: { logo, SocialLink, FooterLink, OppositeLang },
	data() {
        return {
            footerInfos: this.$store.state.footerInfos
        }
    },
}
