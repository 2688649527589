//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'

export default {
	data() {
        return {
            social_data: this.$store.state.social_data
        }
    },
}
