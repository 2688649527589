//
//
//
//
//
//
//
//
//
//
//
//
//
//


import axios from 'axios'
import qs from 'qs'

export default {
  computed: {
    myItems () {
      return this.$store.state.cart
    },

    amount () {
      return this.$store.state.cart.reduce(function(prev, cur) {return prev + (cur.price * cur.quantity)}, 0).toFixed(2)
    }
  },
  data() {
        return {
          myStyle: {
            
              label: 'checkout',
              size:  'responsive',
              shape: 'rect',
              tagline: false,
              color: 'blue'
          }
        }
    },
  

  watch: {
    '$store.state.cart': function() {
      console.log('update cart amount ??')
      this.amount = this.$store.state.cart.reduce(function(prev, cur) {return prev + (cur.price * cur.quantity)}, 0).toFixed(2)
    }
  },
  methods: {
    paymentcompleted(event) {
      // Your response
      this.$store.dispatch('nav/toggleCart')
      this.$store.dispatch('nav/toggleOrder')
      console.log(event)
      this.$store.commit('paypal/SET_PAYPAL_PAYER', event) 
      console.log(this.$store.state.payer)
      axios({
        method: 'post',
        url: process.env.envBaseUrlAdmin+'/wp-admin/admin-ajax.php?action=mail_order_confirm',
        data: qs.stringify(event),
      })

      axios({
        method: 'post',
        url: process.env.envBaseUrlAdmin+'/wp-admin/admin-ajax.php?action=save_order',
        data: qs.stringify(event)
      })
      .then((response) => {
        console.log(response);
      }, (error) => {
        console.log(error);
      });
      console.log(event); 
    },

  }


}
