//
//
//
//
//
//
//


export default {
    computed: {
        toggleCart() {
            return this.$store.getters['nav/toggleCart']
        },
        fullCart() {
            return this.$store.state.cart.length
        }
    },
    methods: {
		clickShowCart(){
			this.$store.dispatch('nav/toggleCart')
		}
	},
}

