//
//
//
//
//
//
//
//


import nav_desktop from '~/components/layout/nav_desktop'
import nav_mobile from '~/components/layout/nav_mobile'


export default {
	
	components: { nav_desktop, nav_mobile },
	
}
