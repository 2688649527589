//
//
//
//
//
//
//

import { mapMutations } from 'vuex'

export default {
	props: {
		closewhat: { type: String }
	},
	methods: {
		clickCloseCart(){
			this.$store.dispatch(this.closewhat)
		}
	},
}

