import { mapMutations } from 'vuex'


class updateTagList {
  constructor () {
    // updateTagList([])
  }

  updateTagList(posts){
    var tags_name = []
    var tags_slug = []
    var tags_id = []
    var tags_data = []
    posts.forEach((item) => { tags_name.push([item.tags_infos.name]) })
    posts.forEach((item) => { tags_slug.push([item.tags_infos.slug]) })
    posts.forEach((item) => { tags_id.push([item.tags_infos.id]) })
    tags_name = Array.from(new Set(tags_name.join(',').split(','))) 
    tags_slug = Array.from(new Set(tags_slug.join(',').split(','))) 
    tags_id = Array.from(new Set(tags_id.join(',').split(','))) 
    tags_name.forEach((item, index) => { tags_data.push({'name':item, 'slug': tags_slug[index], 'id': tags_id[index]}) })
    return tags_data
  }
}

const TagList = new updateTagList()

export default TagList