//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import CloseCart from "~/components/layout/CloseCart.vue"
import ListCartProduct from "~/components/layout/ListCartProduct.vue"
const apiKey = process.env.GOOGLE_MAPS_API_KEY

export default {
	data() {
		return {
			paypal: this.$store.state.paypal.payer,
			transaction_id: this.$store.state.paypal.payer.transactions[0].related_resources[0].sale.id
		}
	},
    computed: {
        toggleOrder() {
            return this.$store.getters['nav/toggleOrder']
        }
    },
	components: { CloseCart, ListCartProduct },
	
	mounted() {
		this.setMap()
	},

	methods: {
		shop_again: function(){
			this.$store.commit('EMPTY_CART','')
        	this.$store.dispatch("nav/toggleOrder")
		},
		setMap(){
			console.log(this.paypal.payer.payer_info.shipping_address.line1+', '+this.paypal.payer.payer_info.shipping_address.postal_code+' '+this.paypal.payer.payer_info.shipping_address.city+', '+this.paypal.payer.payer_info.shipping_address.state+' '+this.paypal.payer.payer_info.shipping_address.country_code)
			const mapOptions = {
				zoom: 18,
				center: new window.google.maps.LatLng(43.758773, -79.391785),
				disableDefaultUI: true,
				zoomControl: true
			};

			const map = new window.google.maps.Map(this.$refs.map, mapOptions)
			const geocoder = new google.maps.Geocoder()
			const address = this.paypal.payer.payer_info.shipping_address.line1+', '+this.paypal.payer.payer_info.shipping_address.postal_code+' '+this.paypal.payer.payer_info.shipping_address.city+', '+this.paypal.payer.payer_info.shipping_address.state+' '+this.paypal.payer.payer_info.shipping_address.country_code
			geocoder.geocode( { 'address': address}, function(results, status) {
				if (status == 'OK') {
					map.setCenter(results[0].geometry.location)
					var marker = new google.maps.Marker({
						map: map,
						position: results[0].geometry.location
					});
					console.log('it work...')
				}
				else {
					alert('Geocode was not successful for the following reason: ' + status)
				}
			})
		},
	},

}

