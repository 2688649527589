//
//
//
//
//
//
//
//

import AppLinkSideBar from "~/components/layout/AppLink.vue"
import TogglerLang from "~/components/layout/TogglerLang.vue"

    export default {
        computed: {
            toggleSidebar() {
                return this.$store.getters['nav/toggleSidebar']
            }
        },
		components: { AppLinkSideBar, TogglerLang }
    }
