import Vuex from 'vuex'
import TagList from '~/lib/TagList'
import CatList from '~/lib/CatList'

export const state = () => ({
  load_more_project_btn: true,
  filteredByCat: null,
  navigation_data: [],
  navigation_footer_data: [],
  social_data: [],
  posts: [],
  tags: [],
  cats: [],
  authors: {},
  current_post: {},
  next_post: {},
  apiURL:null,
  apiTYPE:null,
  local:'',
  needContactForm:false,
  cart:[],
  footerInfos:{},
})

export const mutations = {
  ['POSTS_UPDATE'] (state, payload) {
    state.posts =  payload ? [...payload ] : []
  },
  ['TAGS_UPDATE'] (state, payload) {
    state.tags =  payload ? [...payload ] : []
  },
  ['CATS_UPDATE'] (state, payload) {
    state.cats =  payload ? [...payload ] : []
  },
  ['NAVIGATION_DATA_UPDATE'] (state, payload) {
    state.navigation_data = payload ? [...payload ] : []
  },
  ['NAVIGATION_FOOTER_DATA_UPDATE'] (state, payload) {
    state.navigation_footer_data =  payload ? [...payload ] : []
  },
  ['SOCIAL_DATA_UPDATE'] (state, payload) {
    state.social_data =  payload ? [...payload ] : []
  },
  ['CURRENT_POST_UPDATE'] (state, payload) {
    state.post = payload ? {...payload } : {}
  },
  ['SET_FOOTER_INFOS'] (state, payload) {
    state.footerInfos = payload ? {...payload } : {}
  },
  ['LOAD_MORE_PROJECT'] (state, payload) {
    state.load_more_project_btn = payload
  },
  ['FILTER_BY_CAT'] (state, payload) {
    console.log('FILTER_BY_CAT  = '+payload);
    state.filteredByCat =  payload ?  payload : false
  },
  ['SET_API_URL'] (state, payload) {
    state.apiURL = payload 
  },
  ['SET_API_TYPE'] (state, payload) {
    state.apiTYPE =  payload ?  payload : ''
  },
  ['SET_LOCAL'] (state, payload) {
    state.locale =  payload ?  payload : ''
  },
  ['SET_SHIPPING_FEE'] (state, payload) {
    state.shipping_fee =  payload ?  payload : ''
  },
  ['NEED_CONTACT_FORM'] (state, payload) {
    state.needContactForm =  payload ?  payload : ''
  },
  ['UPDATE_SHIPPING_FEE'] (state, payload) {
    if(state.cart.reduce(function(prev, cur) {return prev + (cur.price * cur.quantity)}, 0)>=100){
      if(state.cart.find(x => x.name === state.shipping_fee)){
        state.cart.find(x => x.name === state.shipping_fee).price = 0
      }
    }else{
      if(state.cart.find(x => x.name === state.shipping_fee)){
        state.cart.find(x => x.name === state.shipping_fee).price = 14
      }
    }
  },
  ['UPDATE_COUPON_VALUE'] (state, payload) {
    if(state.cart.find(x => x.description === '- 10%')){
      var amount = state.cart.reduce(function(prev, cur) {return prev + (cur.price * cur.quantity)}, 0).toFixed(2)
      state.cart.find(x => x.description === '- 10%').price = - (amount / 10).toFixed(2)
    }
    if(state.cart.find(x => x.description === '- 30%')){
      var amount = state.cart.reduce(function(prev, cur) {return prev + (cur.price * cur.quantity)}, 0).toFixed(2)
      console.log(amount);
      state.cart.find(x => x.description === '- 30%').price = - (amount / 3.33).toFixed(2)
    }
  },
  ['UPDATE_CART_QUANTITY'] (state, payload) {
    if(payload.direction=='up'){
      if(state.cart[payload.id].quantity < state.cart[payload.id].sku){
        state.cart[payload.id].quantity += 1
      }else{///// on fait rien y'a plus de stock
        return
      }
    }else{
      state.cart[payload.id].quantity -= 1
      if(state.cart[payload.id].quantity<=0){
        state.cart.splice(payload.id, 1)
      }
    }
    if(state.cart.length>1){
      this.commit('UPDATE_COUPON_VALUE')
      this.commit('UPDATE_SHIPPING_FEE')
    }else{
      state.cart = []
    }
  },
  ['ADD_TO_CART'] (state, payload) {
    if(state.cart.some(e => e.name === payload.name)) {///already in cart
      var pos = state.cart.map(function(e) { return e.name; }).indexOf(payload.name)
      if(state.cart[pos].name!=state.shipping_fee){
        state.cart[pos].quantity += 1
      }
    }else{///not in cart yet 
      if(payload.name!=state.shipping_fee){
        state.cart.unshift( payload )
      }else{
        state.cart.push( payload )
      }
    }
    this.commit('UPDATE_COUPON_VALUE')
    this.commit('UPDATE_SHIPPING_FEE')

  },
  ['ADD_CUPPON_TO_CART'] (state, payload) {
    if(state.cart.some(e => e.name === payload.name)) {///already in cart
    }else{///not in cart yet 
      state.cart.push( payload )  
      this.commit('UPDATE_COUPON_VALUE')
    }
  },
  ['EMPTY_CART'] (state, payload) {
    state.cart = []
  },
  
}

export const actions = {

  async nuxtServerInit({ commit, dispatch, app }) {
    console.log('nuxtServerInit')
    await dispatch('storeLocal')
    await dispatch('storeNav')
    await dispatch('storeNavSocial')
    await dispatch('storeFooterNav')
    await dispatch('storePosts')
    await dispatch('storeShippingFee')
    await dispatch('storeFooterInfo')
  },

  async storeFooterInfo({ commit }) {
    const footerInfos = await this.$axios.$get('acf/v3/options/acf-options-bio')
    commit('SET_FOOTER_INFOS', footerInfos.acf)
  },

  async storeShippingFee({ commit }) {
    const shipping_fee = this.$i18n.t('shipping_fee')
    commit('SET_SHIPPING_FEE', shipping_fee)
  },
  async storeLocal({ commit }) {
    const locale = this.$i18n.locale
    commit('SET_LOCAL', locale)
  },

  async storeNav({ commit }) {
    const navigation = await this.$axios.$get('menus/v1/menus/menu-header-'+this.$i18n.locale)
    commit('NAVIGATION_DATA_UPDATE', navigation.items)
    console.log(navigation);
  },

  async storeNavSocial({ commit }) {
    try {
      const navigationSocial = await this.$axios.$get('menus/v1/menus/menu-social-'+this.$i18n.locale)
      commit('SOCIAL_DATA_UPDATE', navigationSocial.items)
    } catch (err) {
      commit('SOCIAL_DATA_UPDATE', [])
     // Handle error
    }
  },

  async storeFooterNav({ commit }) {
    try {
    const navigationFooter = await this.$axios.$get('menus/v1/menus/menu-footer-'+this.$i18n.locale)
    commit('NAVIGATION_FOOTER_DATA_UPDATE', navigationFooter.items)

    } catch (err) {
      commit('NAVIGATION_FOOTER_DATA_UPDATE', [])
     // Handle error
    }
  },

  async storePosts({ commit }) {
    try {
      const posts = await this.$axios.$get('wp/v2/posts',{
        params: {sticky:true, wpse_custom_order: 'menu_order', order: 'asc', per_page:100, lang:this.$i18n.locale}
      })
      commit('POSTS_UPDATE', posts)
      commit('TAGS_UPDATE', TagList.updateTagList(posts))
      commit('CATS_UPDATE', CatList.updateCatList(posts))
    }
    catch (err) {
    commit('POSTS_UPDATE', [])
    commit('TAGS_UPDATE', [])
    commit('CATS_UPDATE', [])
     // Handle error
    }
   
    
  },
    
}

export const getters = {
    get_current_post_id: state => state.post.id,
    get_navigation_data: state => state.navigation_data,
    get_posts: state => state.posts,
    get_next_post: state => state.next_post,
    get_current_post: state => state.post,
    get_tags_data: state => state.tags,
    get_load_more_project_btn: state => state.load_more_project_btn,
    get_filteredByCat: state => state.filteredByCat,
    get_polylang_translations: state => state.post ? state.post.polylang_translations : [],
    get_api_url: state => state.apiURL,
    get_api_type: state => state.apiTYPE,
}
