//
//
//
//
//
//
//
//

export default {
	watch: {
		'$store.state.post': function() {
			this.getLinkTranslation()
		}
	},
	data() {
	    return {
    		linkTranslation:[]
	    };
	},
	mounted() {
		this.getLinkTranslation()
	},
	methods: {
		getLinkTranslation(){
			this.linkTranslation = this.$store.getters['get_polylang_translations'] 
		},
	},
}
