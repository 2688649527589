import Vuex from 'vuex'

export const state = () => ({
  payer:{}
})

export const mutations = {
  ['SET_PAYPAL_PAYER'] (state, payload) {
  	console.log('SET_PAYPAL_PAYER')
    console.log(payload)
    state.payer =  payload ? {...payload } : {}
  },
}