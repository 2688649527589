import { mapMutations } from 'vuex'


class updateCatList {
  constructor () {
    // updateCatList([])
  }

  updateCatList(posts){
    var cats_name = []
    var cats_slug = []
    var cats_id = []
    var cats_data = []
    posts.forEach((item) => { cats_name.push([item.cats_infos.name]) })
    posts.forEach((item) => { cats_slug.push([item.cats_infos.slug]) })
    posts.forEach((item) => { cats_id.push([item.cats_infos.id]) })
    cats_name = Array.from(new Set(cats_name.join(',').split(','))) 
    cats_slug = Array.from(new Set(cats_slug.join(',').split(','))) 
    cats_id = Array.from(new Set(cats_id.join(',').split(','))) 
    cats_name.forEach((item, index) => { cats_data.push({'name':item, 'slug': cats_slug[index], 'id': cats_id[index]}) })
    return cats_data
  }
}

const CatList = new updateCatList()

export default CatList