//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'

const coupon_ok = process.env.COUPON
console.log('coupon_ok = = '+coupon_ok);

export default {
	props: {
		need_quantity: { type: Boolean }
	},
	computed: {
		cart () {
			return this.$store.state.cart
		},

		amount () {
			return this.calculate_amount()
		}
	},

	watch: {
		'$store.state.cart': function() {
			console.log('update cart amount list product')
			this.amount = this.calculate_amount()
		}
	},

	data() {
		return {
			coupon_ok: process.env.COUPON,
			your_coupon: '',
			need_coupon:true,
			wrong_coupon:false,
		};
	},

	methods: {
		validateCoupon(){
			console.log(this.$config.COUPON);
			if(this.$config.COUPON==this.your_coupon){
				var coupon = {}
				coupon.name = this.$config.COUPON
				coupon.description = '- 30%'
				coupon.quantity = 1
				coupon.price = false
				coupon.currency = 'EUR'
				coupon.sku = 1
				this.$store.commit('ADD_CUPPON_TO_CART',coupon)
				this.need_coupon  = false
				this.wrong_coupon = false
			}else{
				this.wrong_coupon = true
			}
		},
	    calculate_amount() {
	    	var amount = 0.00
	    	if(this.$store.state.cart.length){
	    		amount = this.$store.state.cart.reduce(function(prev, cur) {return prev + (cur.price * cur.quantity)}, 0).toFixed(2)
	    	}

	    	return amount
	    },
	    stepDown(indx){
	    	var payload = {}
	    	payload.id = indx
	    	payload.direction = 'down'
	    	this.$store.commit('UPDATE_CART_QUANTITY',payload)
	    },
	    stepUp(indx){
	    	var payload = {}
	    	payload.id = indx
	    	payload.direction = 'up'
	    	this.$store.commit('UPDATE_CART_QUANTITY',payload)
	    }
	  }
}
