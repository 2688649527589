//
//
//
//
//
//
//
//
//
//
//

import AppLink from "~/components/layout/AppLink.vue"
import logo from "~/components/Logo.vue"
import TogglerLang from '~/components/layout/TogglerLang'

export default {
	
	components: { AppLink, logo, TogglerLang, },
	
}
