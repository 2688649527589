//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BtnPaypal from "~/components/layout/BtnPaypal.vue"
import ListCartProduct from "~/components/layout/ListCartProduct.vue"
import CloseCart from "~/components/layout/CloseCart.vue"

export default {
    computed: {
        toggleCart() {
            return this.$store.getters['nav/toggleCart']
        }
    },
	components: { BtnPaypal, ListCartProduct, CloseCart }
}

