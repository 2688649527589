//
//
//
//
//
//
//
//
//
//
//
//
//

import LogoPath from '~/components/LogoPath.vue'

export default {
	props: {
		no_title: { type: Boolean }
	},
	components: {
		LogoPath,
	},
}

