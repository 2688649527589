//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'

export default {
	data() {
        return {
            show_hidden_menu: false,
            navigation_data: this.$store.state.navigation_data,
			filteredByCat: this.$store.state.filteredByCat,
            folderLang : (this.$store.state.locale=='en') ? '' : 'fr/'
        }
    },
	methods: {
		filterCategory: function (slug) {
			console.log('this.$store.state.filteredbycurrentcat +'+this.$store.state.filteredByCat);
			this.$root.context.store.commit('FILTER_BY_CAT', slug)
			console.log('this.$store.state.filteredbycurrentcat +'+this.$store.state.filteredByCat);
			return false;
		}
	}
}
