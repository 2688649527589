//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'

export default {
	data() {
        return {
            show_hidden_menu: false,
            navigation_footer_data: this.$store.state.navigation_footer_data
        }
    },
}
