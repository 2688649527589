import { render, staticRenderFns } from "./FooterLink.vue?vue&type=template&id=60aac1fd&scoped=true&lang=pug&"
import script from "./FooterLink.vue?vue&type=script&lang=js&"
export * from "./FooterLink.vue?vue&type=script&lang=js&"
import style0 from "./FooterLink.vue?vue&type=style&index=0&id=60aac1fd&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60aac1fd",
  null
  
)

export default component.exports