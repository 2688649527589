// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./nuxt-fontagon-icon.eot?1326dbb11b1a70d3d12a010ea51f8c79");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./nuxt-fontagon-icon.woff2?1326dbb11b1a70d3d12a010ea51f8c79");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./nuxt-fontagon-icon.woff?1326dbb11b1a70d3d12a010ea51f8c79");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".centered{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)}.vert_align_middle{position:absolute;top:50%;transform:translateY(-50%)}.fullsizecover{position:absolute;top:0;left:0;width:100%;height:100%}.full_screen_cover{position:absolute;top:0;left:0;width:100vw;height:100vh}@font-face{font-family:\"nuxt-fontagon-icon\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"embedded-opentype\");src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\");src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff\")}.nf-icons,.nft-icons{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;display:inline-flex;align-items:center;justify-content:center;vertical-align:middle}.nft-icons{width:1em;height:1em;position:relative;fill:currentColor}.nf-icons{font-family:\"nuxt-fontagon-icon\";font-weight:400;font-style:normal;display:inline-block;line-height:1;text-transform:none;letter-spacing:normal;word-wrap:normal;white-space:nowrap;direction:ltr;-webkit-font-smoothing:antialiased;text-rendering:optimizeLegibility;-moz-osx-font-smoothing:grayscale;font-feature-settings:\"liga\"}.nft-discord:before{content:\"\\f101\"}.nft-facebook:before{content:\"\\f102\"}.nft-instagram:before{content:\"\\f103\"}.nft-linkedin:before{content:\"\\f104\"}.nft-malt:before{content:\"\\f105\"}.nft-twitter:before{content:\"\\f106\"}.nft-vimeo:before{content:\"\\f107\"}.nft-whatsapp:before{content:\"\\f108\"}.nft-youtube:before{content:\"\\f109\"}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
