//
//
//
//
//
//
//
//
//

import AppLink from "~/components/layout/AppLink.vue"
import TogglerLang from '~/components/layout/TogglerLang'
import Burger from '~/components/layout/Burger'
import logo from "~/components/Logo.vue"

import { gsap , Back} from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger.min.js";

if (process.client) {
    gsap.registerPlugin(ScrollTrigger);
}

export default {
	
	components: { AppLink, logo, TogglerLang, Burger  },

	mounted(){
		const showAnim = gsap.from('.warp_header_content', { 
			yPercent: -100,
			paused: true,
			duration: 0.2
		}).progress(1);

		ScrollTrigger.create({
			start: "top top",
			end: 99999,
			onUpdate: (self) => {
				self.direction === -1 ? showAnim.play() : showAnim.reverse()
			}
		});
	},

}
