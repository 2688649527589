// States
export const state = () =>({
    
    toggleSidebar: false,
    toggleCart: false,
    toggleOrder: false
    
})

// mutations
export const mutations = {

    TOGGLE_SIDEBAR(state) {
        state.toggleSidebar = !state.toggleSidebar
    },
    TOGGLE_CART(state) {
        state.toggleCart = !state.toggleCart
    },
    TOGGLE_ORDER(state) {
        state.toggleOrder = !state.toggleOrder
    },
    OPEN_CART(state) {
        state.toggleCart = true
    },

}

// actions
export const actions = {

    toggleSidebar({ commit }) {
        commit('TOGGLE_SIDEBAR')
    },
    toggleCart({ commit }) {
        commit('TOGGLE_CART')
    },
    toggleOrder({ commit }) {
        commit('TOGGLE_ORDER')
    },
    openCart({ commit }) {
        commit('OPEN_CART')
    },


}

// Getters
export const getters = {

    toggleSidebar: state => state.toggleSidebar,
    toggleCart: state => state.toggleCart,
    toggleOrder: state => state.toggleOrder,
    openCart: state => state.openCart,

}